import ClickableLink from 'components/Common/Clickable/ClickableLink'
import TextButton from 'components/Luxkit/Button/TextButton'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import Group from 'components/utils/Group'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'

const Root = styled(ClickableLink)`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  padding: ${rem(20)};
  text-align: center;
  border-radius: ${(props) => props.theme.borderRadius.S};
  width: 75vw;

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }

  ${mediaQueryUp.tablet} {
    width: ${rem(364)};
  }
`

interface Props {
  tripId: string
  tripName: string
  onClick?: () => void
}

function AddMoreCard({ tripId, tripName, onClick }: Props) {
  const buttonLabel = tripName.length > 25 ? 'View trip' : `View ${tripName}`

  return (
    <Root to={`/trip-planner/trip/${tripId}/summary`} onClick={onClick}>
      <Group direction="vertical" verticalAlign="center" horizontalAlign="center" gap={8} fullHeight>
        <Heading variant="heading4">Add more to your trip</Heading>
        <BodyText variant="medium">Save offers, create an itinerary, and arrange every aspect of your escape.</BodyText>
        <TextButton kind="primary" nonInteractable>{buttonLabel}</TextButton>
      </Group>
    </Root>
  )
}

export default AddMoreCard
