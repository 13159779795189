import { useEffect } from 'react'
import { useAppDispatch, useAppSelector, shallowEqual } from 'hooks/reduxHooks'
import type { AppDispatch } from 'src/client/store'
import { ThunkAction } from 'redux-thunk'

interface ApiQueryParams<F, D, E> {
  /** Name for the field in the result object that will contain the data (default: 'data') */
  fieldName?: F;
  /** Function to extract the fetched data from the redux state */
  getData: (state: App.State) => D | undefined;
  /** Function to extract the fetch error from the redux state */
  getError: (state: App.State) => E | undefined;
  /** Function to extract the fetching status from the redux state */
  getFetching: (state: App.State) => boolean;
  /** Function returning an action that initiates the fetch. This would usually be a thunk action */
  getFetchAction: () => Parameters<AppDispatch>[0] | ThunkAction<any, App.State, any, any>;
  /** Dependencies that should trigger a fetch if changed */
  fetchDependencies: React.DependencyList;
  /** Data to be used while the real data is being fetched */
  placeholderData?: D;
  /** If false, the fetch won't be initiated (default: true) */
  enabled?: boolean
}

type IdleResult<F extends string> = {
  [key in F]: undefined;
} & {
  status: 'idle';
  error: undefined;
  loading: true;
}

type FetchingResult<F extends string> = {
  [key in F]: undefined;
} & {
  status: 'fetching';
  error: undefined;
  loading: true;
}

type SuccessResult<F extends string, D> = {
  [key in F]: D | undefined;
} & {
  status: 'success';
  error: undefined;
  loading: false;
}

type ErrorResult<F extends string, D, E> = {
  [key in F]: D | undefined;
} & {
  status: 'error';
  error: E;
  loading: false;
}

export type ApiQueryResult<F extends string, D, E> = IdleResult<F> | FetchingResult<F> | SuccessResult<F, D> | ErrorResult<F, D, E>

/**
 * A hook for making nice React Query-esque data hooks for API calls that are done via redux.
 */
function useApiQuery<D, E = any, F extends string = 'data'>(params: ApiQueryParams<F, D, E>): ApiQueryResult<F, D, E> {
  const {
    fieldName = 'data',
    getData,
    getError,
    getFetching,
    getFetchAction,
    fetchDependencies,
    enabled = true,
  } = params

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (enabled) {
      dispatch(getFetchAction())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, ...fetchDependencies])

  const { data, error, fetching } = useAppSelector<{ data: D | undefined, error: E | undefined, fetching: boolean }>((state) => ({
    data: getData(state),
    error: getError(state),
    fetching: getFetching(state),
  }), shallowEqual)

  if (error) {
    return {
      status: 'error',
      [fieldName]: data,
      error,
      loading: false,
    }
  } else if (data) {
    return {
      status: 'success',
      [fieldName]: data,
      error: undefined,
      loading: false,
    }
  } else if (fetching) {
    return {
      status: 'fetching',
      [fieldName]: undefined,
      error: undefined,
      loading: true,
    } as const
  } else {
    return {
      status: 'idle',
      [fieldName]: undefined,
      error: undefined,
      loading: true,
    } as const
  }
}

export default useApiQuery
