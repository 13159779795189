import Heading from 'components/Luxkit/Typography/Heading'
import React from 'react'
import LayoutContainer from 'components/Common/LayoutContainer/LayoutContainer'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { rem } from 'polished'
import styled from 'styled-components'
import OfferTypesCarousel from './OfferTypesCarousel'
import Group from 'components/utils/Group'
import { isWhitelabelBrand } from 'lib/whitelabels/whitelabels'
import config from 'constants/config'

const Root = styled.div`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

function BrowseOfferTypes() {
  return (
    <Root>
      <Group direction="vertical" gap={20}>
        <LayoutContainer>
          <Group direction="vertical" gap={8}>
            <BodyText colour="neutral-two" variant="large">
              Everything travel, all in the one place
            </BodyText>
            <Heading variant="heading2">
              {
                isWhitelabelBrand(config.BRAND) ?
                  <>Explore all offers to find your next <i>getaway</i></> :
                    (<>Browse all offers to find your next <i>escape</i></>)
              }
            </Heading>
          </Group>
        </LayoutContainer>
        <OfferTypesCarousel />
      </Group>
    </Root>
  )
}

export default React.memo(BrowseOfferTypes)
