import { rem } from 'polished'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import Body from 'components/Luxkit/Typography/BodyText'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useDeleteTripItemsBasedOnOrderId, useDeleteTripItem } from 'tripPlanner/hooks/api/tripItem'
import useModalCallbacks from 'tripPlanner/hooks/View/useModalCallbacks'
import { TripItem } from 'tripPlanner/types/tripItem'
import { getOrderId, typeConstToTitle } from 'tripPlanner/utils/index'
import noop from 'lib/function/noop'
import { capitaliseAll } from 'lib/string/stringUtils'
import config from 'constants/config'

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: ${rem(40)};
  justify-content: center;
  gap: ${rem(24)};
`

interface Props {
  item: TripItem
  onDeleted?: (item: TripItem) => void
}

function DeleteTripItem(props: Props) {
  const { item, onDeleted = noop } = props
  const { id, tripId, type } = item

  const { closeModal, goBack } = useModalCallbacks()
  const modalElement = useModalElementContext()
  const close = useCallback(() => {
    closeModal()
    modalElement?.resolve()
  }, [closeModal, modalElement])

  const { mutate: deleteTripItemMutate, isLoading } = useDeleteTripItem({
    onSuccess: () => {
      close()
      onDeleted(item)
    },
    onError: () => {},
  })

  const { mutate: deleteTripItemsByOrderIdMutate, isLoading: isLoadingDelete } =
    useDeleteTripItemsBasedOnOrderId({
      onSuccess: close,
      onError: () => {},
    })

  const typeTitle = typeConstToTitle(type).toLowerCase()

  const orderId = getOrderId(item)

  const confirmDelete = useCallback(() => {
    if (isLoading || isLoadingDelete) return
    if (!orderId) {
      deleteTripItemMutate({
        tripId,
        tripItemId: id,
      })
    } else {
      deleteTripItemsByOrderIdMutate({
        tripId,
        orderId,
      })
    }
  }, [
    deleteTripItemMutate,
    deleteTripItemsByOrderIdMutate,
    id,
    isLoading,
    isLoadingDelete,
    orderId,
    tripId,
  ])

  return (
    <>
      <ModalHeader
        title={
          orderId ?
            'Delete booking from trip' :
            `Delete ${typeTitle} from trip?`
        }
        onBackButtonClick={goBack}
        onCloseButtonClick={close}
      />
      <ModalBody>
        <ModalContent>
          <ContentWrap>
            {!orderId && (
              <Body variant="large" colour="neutral-one">
                Are you sure you want to delete this {typeTitle} from your trip?
              </Body>
            )}
            {orderId && (
              <>
                <Body variant="large" colour="neutral-one">
                  Are you sure you want to delete this booking from your trip?
                  Any other items in the same booking will be deleted.
                </Body>
                <Body variant="large" colour="neutral-one">
                  However your booking will not be cancelled, and you'll still
                  be able to view all your bookings in My {capitaliseAll(config.brandConfig.purchaseSynonym)}s.
                </Body>
              </>
            )}
            {(isLoading || isLoadingDelete) && <LoadingIndicator floating />}
          </ContentWrap>
        </ModalContent>
      </ModalBody>
      <ModalFooter
        primaryActionProps={{
          children: 'Delete',
          disabled: isLoading || isLoadingDelete,
          onClick: confirmDelete,
        }}
        secondaryActionProps={{
          children: 'Cancel',
          disabled: isLoading || isLoadingDelete,
          onClick: close,
        }}
      />
    </>
  )
}

export default DeleteTripItem
