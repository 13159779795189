import Image from 'components/Common/Image/Image'
import TextButton from 'components/Luxkit/Button/TextButton'
import LinePhoneIcon from 'components/Luxkit/Icons/line/LinePhoneIcon'
import LineWhatsappIcon from 'components/Luxkit/Icons/line/LineWhatsappIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Heading from 'components/Luxkit/Typography/Heading'
import { mediaQueryUp } from 'components/utils/breakpoint'
import config from 'constants/config'
import GeoContext from 'contexts/geoContext'
import { useResponsive } from 'hooks/useResponsive'
import { useRenderWhatsApp } from 'hooks/useRenderWhatsApp'
import { rem } from 'polished'
import React, { useContext, useMemo } from 'react'
import styled from 'styled-components'
import cn from 'clsx'
import ContactContext from 'contexts/contactContext'
import { NO_24_7_SUPPORT_REGIONS } from 'constants/config/region'
import { getCustomerSupportAgentImage } from '../CustomerSupport/getAgentImage'

const mobileWidth = 150
const desktopWidth = 360

const SupportContainer = styled.div`
  background-color: ${props => props.theme.palette.neutral.default.seven};
  display: grid;
  grid-template-areas:
    "mainBody image"
    "mainBody image"
    "buttons buttons";
  padding: ${rem(28)} ${rem(24)};

  ${mediaQueryUp.tablet} {
    grid-template-areas:
      "mainBody image"
      "mainBody image"
      "buttons image";
    padding: ${rem(32)};
  }

  ${mediaQueryUp.desktop} {
    padding: unset;
    justify-content: space-between;
    padding-left: ${rem(32)};
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-area: mainBody;
  grid-gap: ${rem(8)};

  ${mediaQueryUp.desktop} {
    padding-top: ${rem(32)};
  }
`

const ButtonContainer = styled.div`
  grid-area: buttons;
  display: grid;
  grid-gap: ${rem(8)};
  margin-top: ${rem(16)};

  &.two-buttons {
    grid-template-columns: 1fr 1fr;
  }

  ${mediaQueryUp.desktop} {
    padding-bottom: ${rem(32)};
  }
`

const PhoneButton = styled(TextButton)`
  ${mediaQueryUp.tablet} {
    width: fit-content;
  }
`

const SupportImageContainer = styled.div`
  grid-area: image;
  display: flex;
`

const SupportImage = styled(Image)`
  min-width: ${rem(100)};
  min-height: ${rem(120)};
  max-height: ${rem(180)};
  max-width:${rem(mobileWidth)};
  padding-left: ${rem(20)};

  ${mediaQueryUp.desktop} {
    min-width: unset;
    min-height: unset;
    max-height: unset;
    max-width: ${rem(desktopWidth)};
  }
`

interface Props {
  title?: string;
  description?: string;
}

function SupportTile(props: Props) {
  const { currentRegionCode } = useContext(GeoContext)
  const {
    title = `Our team are available ${NO_24_7_SUPPORT_REGIONS.includes(currentRegionCode) ? '7 days a week from 9am to 7pm' : '24 hours, 7 days'}`,
    description = 'Call our dedicated customer service team anytime, whenever you need us.',
  } = props

  const supportContact = useContext(ContactContext)
  const [match] = useResponsive()
  const isXXS = useMemo(() => match.mobile, [match])
  const isXS = useMemo(() => match.mobile, [match])
  const shouldRenderWhatsApp = useRenderWhatsApp(currentRegionCode)
  const renderWhatsApp = shouldRenderWhatsApp && isXXS
  const isMobileSize = !!(isXS || isXXS)
  const imageId = getCustomerSupportAgentImage('right', !isMobileSize, currentRegionCode)
  const imageWidth = (isXS || isXXS) ? mobileWidth : desktopWidth
  const callUsText = isXXS ? 'Call us' : `Call ${supportContact.local.humanReadable}`

  return (
    <SupportContainer>
      <ContentContainer>
        <Heading variant="heading5">{title}</Heading>
        <BodyText variant="large">{description}</BodyText>
      </ContentContainer>
      <ButtonContainer className={cn({ 'two-buttons': renderWhatsApp })}>
        <PhoneButton
          kind="secondary"
          size="medium"
          shape="square"
          startIcon={<LinePhoneIcon/>}
          href={`tel:${supportContact.local.number}`}
        >
          {callUsText}
        </PhoneButton>
        {renderWhatsApp &&
          <TextButton
            kind="secondary"
            size="medium"
            shape="square"
            startIcon={<LineWhatsappIcon/>}
            href={`https://wa.me/${config.WHATS_APP_NUMBER}`}
          >
            WhatsApp
          </TextButton>
        }
      </ButtonContainer>
      <SupportImageContainer>
        <SupportImage fit="center" id={imageId} alt="Support" width={imageWidth} dpr={2} />
      </SupportImageContainer>
    </SupportContainer>
  )
}

export default SupportTile
