export type CSImageAlignment = 'left' | 'right'

export function getCustomerSupportAgentImage(alignment: CSImageAlignment, isWideImage: boolean, region: string | undefined) {
  if (region === 'HK' || region === 'SG') {
    if (alignment === 'left') {
      return isWideImage ? '9zfajijv2lx81f6gyfo' : 'mxv1ewoo3pe8l8ddl4e'
    } else {
      return isWideImage ? '56l0glrmixeitosoetkn9' : 'us3u14wkihlv6zs4anuh'
    }
  } else {
    if (alignment === 'right') {
      return isWideImage ? 'kra2y9app8i6mpnli' : 'd5sfjdbpg47k0yfcxxzj'
    } else {
      return isWideImage ? 'f2cbkfa3neeqk0suw16u' : 'l2rjsmuqvmafzqn1p2p'
    }
  }
}
