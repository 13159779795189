import React, { useCallback, useContext, useMemo } from 'react'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import GeoContext from 'contexts/geoContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import useCheapestOfTourV2Offer from 'hooks/TourV2/useCheapestOfTourV2Offer'
import useTourV2VariationDetails from 'hooks/TourV2/useTourV2VariationDetails'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import noop from 'lib/function/noop'
import offerPageURL from 'lib/offer/offerPageURL'
import {
  TourV2OfferCardPriceDetails,
  getItineraryDaysCount,
} from 'tripPlanner/components/Bookmark/BookmarkCard/TourV2Card'
import BookmarkLocation from 'tripPlanner/components/Bookmark/BookmarkCardNew/BookmarkLocation'
import MiniSavedOfferCard from '../MiniSavedOfferCard'
import { TourItem } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatDatesAndDays,
  formatDurationDaysIfMultipleDays,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'

interface Props {
  item: TourItem
  offer: App.Tours.TourV2Offer
  onClick?: () => void
  position?: number
}

export default function TourV2ItemCard({
  offer,
  item,
  onClick = noop,
  position,
}: Props) {
  const { cheapestPurchasableOption, cheapestVariation } =
    useCheapestOfTourV2Offer(offer)
  const { startLocation, endLocation, hasTheSameStartAndEndLocation } =
    useTourV2VariationDetails(cheapestVariation)

  const { currentCurrency } = useContext(GeoContext)

  const timeFormat = useRegionTimeFormat()

  const locationDescription = useMemo(
    () => (
      <BookmarkLocation
        startLocation={startLocation}
        endLocation={
          hasTheSameStartAndEndLocation ? startLocation : endLocation
        }
        variant="start_and_end"
        showIcon={false}
      />
    ),
    [endLocation, hasTheSameStartAndEndLocation, startLocation],
  )

  const priceAreaContent = useMemo(() => {
    if (cheapestPurchasableOption && cheapestVariation) {
      return (
        <TourV2OfferCardPriceDetails
          depositComponent={null}
          purchasableOption={cheapestPurchasableOption}
          duration={getItineraryDaysCount(cheapestVariation)}
          currencyCode={currentCurrency}
          discountDisplayType="inlineBadge"
        />
      )
    }
    return null
  }, [currentCurrency, cheapestPurchasableOption, cheapestVariation])

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
        key: EventDataKey.ProductImpression,
      })
    }
  }, [dispatchOfferListEvent, offer, position])

  const onClickCard = useCallback(() => {
    onClick()
    if (position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
        key: EventDataKey.ProductClick,
      })
    }
  }, [onClick, dispatchOfferListEvent, offer, position])

  const isSoldOut = !cheapestPurchasableOption || !cheapestVariation

  const variationForDisplay =
    cheapestVariation ?? Object.values(offer.variations)[0]
  const image = variationForDisplay?.images[0]

  const timeDescription = formatDatesAndDays(item, timeFormat)
  const durationDescription = formatDurationDaysIfMultipleDays(item)

  let tileLink: string | undefined
  if (item.sourceType === 'LE_Tour' && item.isBooked && item.confirmationCode) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item, true, { offer })
  } else {
    tileLink = offerPageURL(offer)
  }

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.name}
      imgId={image?.id}
      imgSrc={image?.url}
      locationDescription={locationDescription}
      descriptors={[timeDescription, durationDescription]}
      priceAreaContent={priceAreaContent}
      isSoldOut={isSoldOut}
    />
  )
}
