import { useEffect, useRef } from 'react'

/**
 * Returns a promise that resolves when the passed-in value is defined. It will always return
 * the same promise for any given component instance where it's used.
 *
 * This was created to deal with a fairly specific situation and shouldn't need to be used often.
 * The use case was an analytics event that needed an offer object. When the event was triggered,
 * the offer wasn't necessarily loaded yet. Therefore it was necessary to await the existence
 * of the offer before sending the event.
 *
 * @param any - A value, possibly `undefined`
 * @returns A promise. As soon as the hook gets called with a non-`undefined` value, the promise
 * will resolve with that value.
 */
export default function useAwaitExistence<T>(value: T | undefined): Promise<T> {
  const promiseRef = useRef<Promise<T> | undefined>(undefined)
  const resolveRef = useRef<(value: T) => void | undefined>(undefined)
  const resolvedRef = useRef(false)

  if (!promiseRef.current) {
    promiseRef.current = new Promise<T>((resolve) => {
      resolveRef.current = resolve
    })
  }

  useEffect(() => {
    if (value && !resolvedRef.current) {
      resolveRef.current?.(value)
      resolvedRef.current = true
    }
  })

  return promiseRef.current
}
