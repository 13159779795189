import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'
import { useEffect } from 'react'
import { fetchTourV2SnapshotDetails } from 'actions/TourV2Actions'

function useTourV2Snapshot(
  reservationId: string | undefined,
): [App.Tours.TourV2OfferSnapshot | undefined, boolean] {
  const dispatch = useAppDispatch()

  const tourSnapshot = useAppSelector((state) => reservationId ? state.order.tourV2SnapshotDetails[reservationId] : undefined)
  const tourSnapshotLoading = useAppSelector((state) => reservationId ? state.order.tourV2SnapshotsLoading[reservationId] : false)

  useEffect(() => {
    if (reservationId) {
      dispatch(fetchTourV2SnapshotDetails(reservationId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reservationId, fetchTourV2SnapshotDetails])

  return [tourSnapshot, tourSnapshotLoading]
}

export default useTourV2Snapshot
