import React, { useCallback, useMemo, useState } from 'react'
import { useRecordTripView, useTrip, useTrips } from 'tripPlanner/hooks/api/trip'
import { EmptyArray } from 'lib/array/arrayUtils'
import { BasicTrip } from 'tripPlanner/types/common'
import TripHomepageModule from './TripHomepageModule'
import { getCanShowTripModule, getTripToShowOnHomepage } from './utils'
import { useUserSettings } from 'tripPlanner/hooks/api/userSettings'

function TripHomepageContainer() {
  const [selectedHomepageTripId, setSelectedHomepageTripId] = useState<string | undefined>()

  const { data: trips = EmptyArray as Array<BasicTrip> } = useTrips()

  const { data: userSettings } = useUserSettings()

  const canShowTripModule = getCanShowTripModule(userSettings)

  const tripToShow = useMemo(() => {
    const manuallySelectedTrip = selectedHomepageTripId ? trips.find(trip => trip.id === selectedHomepageTripId) : undefined
    if (manuallySelectedTrip) {
      return manuallySelectedTrip
    }

    return getTripToShowOnHomepage(trips)
  }, [trips, selectedHomepageTripId])

  const { data: trip, isError } = useTrip({
    tripId: tripToShow?.id,
    withPlaceholder: false,
    enabled: canShowTripModule && !!tripToShow,
  })

  const { mutate: recordTripView } = useRecordTripView()

  const onSelectTrip = useCallback((tripId: string) => {
    setSelectedHomepageTripId(tripId)
    recordTripView(tripId)
  }, [recordTripView])

  if (!canShowTripModule || !tripToShow || isError) {
    return null
  }

  return (
    <TripHomepageModule basicTrip={tripToShow} trip={trip} onSelectTrip={trips.length > 1 ? onSelectTrip : undefined} />
  )
}

export default TripHomepageContainer
