import React, { ComponentProps } from 'react'
import styled from 'styled-components'

import ResponsiveImage from 'components/Common/ResponsiveImage'
import AspectRatio from 'components/utils/AspectRatio'

const Outer = styled.div`
  display: flex;
  height: 100%;
`

const OverlappingAspectRatio = styled(AspectRatio)`
  width: 100%;
  margin-right: -100%;
  pointer-events: none;
`

const ImgWrapper = styled.div`
  width: 100%;
`

interface Props extends React.ComponentProps<typeof ResponsiveImage> {
  minHeightAspectRatio?: Pick<ComponentProps<typeof AspectRatio>, 'ratio' | 'tabletRatio' | 'desktopRatio' | 'largeDesktopRatio'>
}

/*
  The image will be at least as tall as the provided aspect ratio at each breakpoint.
  It will be taller if the image itself is taller, or if the parent is taller.
*/

const HeightFillingImage = React.forwardRef<HTMLImageElement, Props>((props, ref) => {
  const { minHeightAspectRatio, fit = 'center', ...imgProps } = props

  return <Outer>
    <OverlappingAspectRatio {...minHeightAspectRatio}/>
    <ImgWrapper>
      <ResponsiveImage {...imgProps} ref={ref} fit={fit} />
    </ImgWrapper>
  </Outer>
})

export default HeightFillingImage
