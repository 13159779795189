import React, { useCallback, useContext, useMemo } from 'react'

import { PlannedItemPriceContent } from '../../ItemCard/PlannedItemPriceContent'
import LoadingItemCard from '../LoadingItemCard'

import OfferListEventsContext, {
  OfferListEvents,
} from 'components/OfferList/OfferListEventsContext'
import { EventDataKey } from 'home/pages/HomePage/useHomepageAnalytics'
import { useRegionTimeFormat } from 'hooks/useRegionTimeFormat'
import noop from 'lib/function/noop'
import { isLEOffer, isCruiseV1Offer } from 'lib/offer/offerTypes'
import { scheduleIsCurrent } from 'lib/offer/scheduleStatusUtils'
import BookmarkLocation from 'tripPlanner/components/Bookmark/BookmarkCardNew/BookmarkLocation'
import MiniSavedOfferCard from '../MiniSavedOfferCard'
import useOffer from 'hooks/Offers/useOffer'
import { CruiseItem } from 'tripPlanner/types/tripItem'
import {
  buildMyEscapesOrderLink,
  formatDatesAndDays,
  isLeBookmark,
} from 'tripPlanner/utils'
import { buildProductLink } from 'tripPlanner/utils/bookmark/restoreCart'

interface Props {
  item: CruiseItem
  onClick?: () => void
  position?: number
}

export default function CruiseV1ItemCard({
  item,
  onClick = noop,
  position,
}: Props) {
  const [offer, isLoading] = useOffer<App.Offer>(item.code)

  const isOfferAvailable =
    offer && isLEOffer(offer) ?
      scheduleIsCurrent(offer.onlinePurchaseSchedule) :
      true

  const priceAreaContent = useMemo(() => {
    if (isLeBookmark(item)) {
      const { discountPercent, currencyCode, price } = item.savedItemData
      return (
        <PlannedItemPriceContent
          currencyCode={currencyCode}
          price={price}
          discountPercent={discountPercent}
          isOfferAvailable={isOfferAvailable}
          discountDisplayType="inlineBadge"
        />
      )
    }
    return null
  }, [isOfferAvailable, item])

  const locationDescription = useMemo(
    () => (
      <BookmarkLocation
        variant="single"
        startLocation={item.startPlace.name}
        showIcon={false}
      />
    ),
    [item.startPlace.name],
  )

  const timeFormat = useRegionTimeFormat()

  const timeDescription: string | undefined = formatDatesAndDays(
    item,
    timeFormat,
  )

  const dispatchOfferListEvent = useContext(OfferListEventsContext)

  const onImpression = useCallback(() => {
    if (offer && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productImpression,
        offer,
        position,
        key: EventDataKey.ProductImpression,
      })
    }
  }, [dispatchOfferListEvent, offer, position])

  const onClickCard = useCallback(() => {
    onClick()
    if (offer && position !== undefined) {
      dispatchOfferListEvent({
        type: OfferListEvents.productClick,
        offer,
        position,
        key: EventDataKey.ProductClick,
      })
    }
  }, [onClick, dispatchOfferListEvent, offer, position])

  if (
    !offer ||
    !isCruiseV1Offer(offer) ||
    (item.savedItemData && item.savedItemData.offerType !== 'tour')
  ) {
    return null
  }

  let tileLink: string | undefined
  if (
    item.sourceType === 'LE_Cruise' &&
    item.isBooked &&
    item.confirmationCode
  ) {
    tileLink = buildMyEscapesOrderLink(item)
  } else if (isLeBookmark(item)) {
    tileLink = buildProductLink(item, true, { offer })
  }

  if (isLoading)
  { return (
    <LoadingItemCard
      itemType={item.type}
      includeDescriptors={!!item.startDate}
    />
  ) }

  return (
    <MiniSavedOfferCard
      to={tileLink}
      onClick={onClickCard}
      onImpression={onImpression}
      item={item}
      name={item.name}
      imgId={offer.image.id}
      locationDescription={locationDescription}
      descriptors={[timeDescription]}
      priceAreaContent={priceAreaContent}
      isSoldOut={!isOfferAvailable}
    />
  )
}
