import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { rem } from 'polished'
import styled from 'styled-components'
import moment from 'moment'
import { useInView } from 'react-intersection-observer'

import LayoutContainer from 'components/Common/LayoutContainer'
import { BasicTrip, FullTrip } from 'tripPlanner/types/common'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import Heading from 'components/Luxkit/Typography/Heading'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import TextLink from 'components/Luxkit/TextLink'
import Label from 'components/Luxkit/Label'
import BodyTextBlock from 'components/Luxkit/TextBlocks/BodyTextBlock'
import LineMapMarkerIcon from 'components/Luxkit/Icons/line/LineMapMarkerIcon'
import { formatCasualDateRange, formatTripTravellers, hasTravellers } from 'tripPlanner/utils'
import LineCalendarIcon from 'components/Luxkit/Icons/line/LineCalendarIcon'
import LineUsersAltIcon from 'components/Luxkit/Icons/line/LineUsersAltIcon'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { pluralizeToString } from 'lib/string/pluralize'
import { getDestinationsLabel } from 'tripPlanner/utils/places'
import { useScreenSizeOnly } from 'hooks/useScreenSize'
import TripContext, { TripContextValue } from 'tripPlanner/contexts/TripContext'
import { useTripPermissions } from 'tripPlanner/hooks/useTripPermissions'
import OnlyIfChildren from 'components/Common/OnlyIfChildren'
import IconButton from 'components/Luxkit/Button/IconButton'
import LineAngleDownIcon from 'components/Luxkit/Icons/line/LineAngleDownIcon'
import ModalContext from 'contexts/ModalContext'
import TripSelect from './TripSelect/TripSelect'
import TripOfferCarousel from './TripOfferCarousel'
import TripOfferCarouselSkeleton from './TripOfferCarouselSkeleton'
import { fireInteractionEvent } from 'api/googleTagManager'
import { homepageTripModuleBasicEvent, homepageTripModuleViewTripClick } from 'analytics/eventDefinitions'
import LineEllipsisVIcon from 'components/Luxkit/Icons/line/LineEllipsisVIcon'
import ListItem from 'components/Luxkit/List/ListItem'
import { useUpdateUserSettings } from 'tripPlanner/hooks/api/userSettings'
import DropdownList from 'components/Luxkit/Dropdown/List/DropdownList'

const Container = styled(VerticalSpacer)`
  padding-top: ${rem(40)};
  padding-bottom: ${rem(40)};
`

/*
  This is to prevent the trip selection icon from wrapping on its own line
  without any of the trip name text, which looks bad.
*/
const NoWrap = styled.span`
  white-space: nowrap;
`

interface Props {
  basicTrip: BasicTrip
  /** If this is undefined, show loading skeletons for item cards */
  trip?: FullTrip
  onSelectTrip?: (tripId: string) => void
}

function TripHomepageModule({ basicTrip, trip, onSelectTrip }: Props) {
  const isMobile = useScreenSizeOnly('mobile')
  const tripInfoElements: Array<React.ReactNode> = []

  if (basicTrip.destinationsGeo && basicTrip.destinationsGeo.length > 0) {
    tripInfoElements.push(<BodyTextBlock variant="medium" startIcon={<LineMapMarkerIcon />} wrap="no-wrap">
      {getDestinationsLabel(basicTrip.destinationsGeo, 1)}
    </BodyTextBlock>)
  }
  if (basicTrip.startDate && !isMobile) {
    tripInfoElements.push(<BodyTextBlock variant="medium" startIcon={<LineCalendarIcon />} wrap="no-wrap">
      {formatCasualDateRange(basicTrip.startDate, basicTrip.endDate)}
    </BodyTextBlock>)
  }
  if (trip && hasTravellers(trip.travellerRooms) && !isMobile) {
    tripInfoElements.push(<BodyTextBlock variant="medium" startIcon={<LineUsersAltIcon />} wrap="no-wrap">
      {formatTripTravellers(trip.travellerRooms)}
    </BodyTextBlock>)
  }

  let timeToGoLabel: string | undefined = undefined
  if (basicTrip.startDate?.isAfter(moment().add(1, 'day'))) {
    const daysToGo = basicTrip.startDate.diff(moment().startOf('day'), 'days')
    if (daysToGo > 56) {
      const monthsToGo = basicTrip.startDate.diff(moment(), 'months')
      timeToGoLabel = `${pluralizeToString('month', monthsToGo)} to go`
    } else if (daysToGo >= 21) {
      const weeksToGo = basicTrip.startDate.diff(moment(), 'weeks')
      timeToGoLabel = `${pluralizeToString('week', weeksToGo)} to go`
    } else {
      timeToGoLabel = `${pluralizeToString('day', daysToGo)} to go`
    }
    timeToGoLabel = timeToGoLabel.toLocaleUpperCase()
  }

  const permissions = useTripPermissions(basicTrip.id)

  const tripContextValue = useMemo<TripContextValue>(
    () => ({
      tripId: basicTrip.id,
      viewAsTemplate: false,
      permissions,
      isPublicTrip: false,
      name: basicTrip.name,
      displayContext: 'homepage',
    }),
    [basicTrip.id, basicTrip.name, permissions],
  )

  const showModal = useContext(ModalContext)

  const showTripSelector = !!onSelectTrip
  const onClickSelectTrip = useCallback(() => {
    if (onSelectTrip) {
      showModal(<TripSelect onSelectTrip={onSelectTrip} />)
      fireInteractionEvent(homepageTripModuleBasicEvent('change_trip'))
    }
  }, [onSelectTrip, showModal])

  const onClickViewTrip = useCallback(() => {
    fireInteractionEvent(homepageTripModuleViewTripClick('primary'))
  }, [])

  const [containerRef, isModuleInView] = useInView({ triggerOnce: true, threshold: 0.5 })

  useEffect(() => {
    if (isModuleInView) {
      fireInteractionEvent(homepageTripModuleBasicEvent('impression'))
    }
  }, [isModuleInView])

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = useCallback(() => {
    if (!dropdownOpen) {
      fireInteractionEvent(homepageTripModuleBasicEvent('open_menu'))
    }
    setDropdownOpen(!dropdownOpen)
  }, [dropdownOpen])
  const closeDropdown = useCallback(() => setDropdownOpen(false), [])

  const menuTriggerRef = useRef<HTMLButtonElement>(null)

  const { mutate: updateUserSettings } = useUpdateUserSettings()
  const dismiss = useCallback(() => {
    fireInteractionEvent(homepageTripModuleBasicEvent('dismiss'))
    updateUserSettings({ homepageTripModuleDismissedAt: Date.now() })
  }, [updateUserSettings])

  return (
    <TripContext.Provider value={tripContextValue}>
      <Container gap={16} ref={containerRef}>
        <LayoutContainer>
          <Group direction="horizontal" verticalAlign="start" horizontalAlign="space-between" gap={20}>
            <VerticalSpacer gap={12}>
              <Heading variant="heading2">
                Continue planning <i>{basicTrip.name}</i>
                {showTripSelector && <NoWrap>&nbsp;<IconButton kind="tertiary" variant="dark" aria-label="Select trip to preview" onClick={onClickSelectTrip}><LineAngleDownIcon /></IconButton></NoWrap>}
              </Heading>

              <Group direction="vertical" horizontalAlign="stretch" gap={8} desktopDirection="horizontal" desktopHorizontalAlign="start" desktopVerticalAlign="center" desktopGap={12} wrap="wrap">
                <OnlyIfChildren as={Group} direction="horizontal" horizontalAlign="start" verticalAlign="center" gap={8} wrap="wrap">
                  {timeToGoLabel && <CSSBreakpoint min="desktop"><Label kind="primary" variant="dark">{timeToGoLabel}</Label></CSSBreakpoint>}
                  {tripInfoElements.map((element, index) => <React.Fragment key={index}>
                    {index > 0 && <BodyText variant="medium">&middot;</BodyText>}
                    {element}
                  </React.Fragment>)}
                </OnlyIfChildren>
                <Group direction="horizontal" horizontalAlign="start" verticalAlign="center" gap={8} wrap="wrap">
                  {timeToGoLabel && <CSSBreakpoint max="tablet"><Label kind="primary" variant="dark">{timeToGoLabel}</Label></CSSBreakpoint>}
                  <TextLink to={`/trip-planner/trip/${basicTrip.id}/summary`} onClick={onClickViewTrip} size="medium">View trip</TextLink>
                </Group>
              </Group>
            </VerticalSpacer>

            <Group direction="horizontal" gap={16}>

              <IconButton
                kind="tertiary"
                shape="circle"
                aria-label="action menu"
                onClick={toggleDropdown}
                ref={menuTriggerRef}
              >
                <LineEllipsisVIcon />
              </IconButton>
              <DropdownList
                size="S"
                triggerRef={menuTriggerRef}
                anchorRef={menuTriggerRef}
                open={dropdownOpen}
                onClose={closeDropdown}
                placement="bottom-end"
              >
                <ListItem title="Dismiss for now" onClick={dismiss} />
              </DropdownList>
            </Group>
          </Group>
        </LayoutContainer>

        {trip && <TripOfferCarousel tripId={basicTrip.id} tripName={basicTrip.name} items={trip.items} />}
        {!trip && <TripOfferCarouselSkeleton />}
      </Container>
    </TripContext.Provider>
  )
}

export default TripHomepageModule
