import React from 'react'

import BodyText from 'components/Luxkit/Typography/BodyText'
import { MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE } from 'constants/content'
import getValueOffPercent from 'lib/offer/getValueOffPercent'
import PriceDetails from 'tripPlanner/components/ItemOfferPrice/PriceDetails'

interface Props {
  price?: number
  currencyCode: string
  value?: number
  depositComponent?: React.ReactElement
  className?: string
  cabinCategory?: string
  isOfferAvailable: boolean
}

function CruiseV2SavedPriceDetails({
  price,
  currencyCode,
  value,
  depositComponent,
  className,
  cabinCategory,
  isOfferAvailable,
}: Props) {
  const priceLabel = cabinCategory ? `${cabinCategory} from` : 'From'

  if (!isOfferAvailable) {
    return (
      <BodyText variant="large" weight="bold">
        Sold Out
      </BodyText>
    )
  }

  if (!price) {
    return null
  }

  let discount: number | undefined = value ?
    getValueOffPercent(value, price) :
    0
  if (discount < MINIMUM_DISCOUNT_TO_SHOW_PERCENTAGE_BADGE) {
    discount = undefined
  }

  return (
    <PriceDetails
      currencyCode={currencyCode}
      price={price}
      discount={discount}
      priceLabel={priceLabel}
      depositComponent={depositComponent}
      discountDisplayType={discount ? 'inlineBadge' : 'none'}
      className={className}
      totalLabel="/person"
    />
  )
}

export default CruiseV2SavedPriceDetails
