import React, { useMemo, useState } from 'react'
import useFlightDealLocations from 'home/hooks/useFlightDealLocations'
import { sortBy, uniqueBy } from 'lib/array/arrayUtils'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import Group from 'components/utils/Group'
import FlightDealsCarousel from './FlightDealsCarousel/FlightDealsCarousel'
import Image from 'components/Common/Image'
import Heading from 'components/Luxkit/Typography/Heading'
import LayoutContainer from 'components/Common/LayoutContainer'

interface Props {
  flightDeals: Array<App.FlightDeal>;
  flightDealsLoading: boolean;
}

function FlightDeals(props: Props) {
  const { flightDeals, flightDealsLoading } = props
  const [airports, defaultAirport] = useFlightDealLocations()
  const [selectedAirport, setAirport] = useState<App.Airport | undefined>()

  const airport = selectedAirport ?? defaultAirport
  const filteredFlightDeals = useMemo(() => {
    if (airport) {
      const deals = flightDeals.filter(({ originAirportCode }) => originAirportCode === airport.code)
      return sortBy(deals, (deal) => deal.hierarchy, 'asc')
    }
    return []
  }, [airport, flightDeals])

  const hasMultipleAirlines = useMemo(() => uniqueBy(filteredFlightDeals, deal => deal.carrier.code).length > 1, [filteredFlightDeals])

  return (
    <VerticalSpacer gap={16}>
      <LayoutContainer>
        {!!filteredFlightDeals.length && (
          <Group direction="horizontal" gap={8} verticalAlign="end">
            <Heading variant="heading4">Flights sale with{hasMultipleAirlines && ' our partner airlines'}</Heading>
            {!hasMultipleAirlines && <Image image={filteredFlightDeals[0].carrier.logo} width={100} alt={`Logo of ${filteredFlightDeals[0].carrier.name}`} />}
          </Group>
        )}
      </LayoutContainer>

      <FlightDealsCarousel
        title={`Limited-time flight offers ${airports.length > 1 ? ' from' : ''}`}
        onAirportChange={setAirport}
        airports={airports}
        flightDeals={flightDeals}
        defaultAirport={defaultAirport}
        selectedAirport={selectedAirport}
        loading={flightDealsLoading}
        showLookingForAFlight
        showViewAll
      />
    </VerticalSpacer>
  )
}

export default FlightDeals
