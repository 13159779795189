import Modal from 'components/Luxkit/Modal/Modal'
import React, { useCallback, useMemo } from 'react'
import TripPanel from './TripPanel'
import LoadingIndicator from 'components/Common/Loading/LoadingIndicator'
import { useTrips } from 'tripPlanner/hooks/api/trip'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { EmptyArray, sortBy } from 'lib/array/arrayUtils'
import { BasicTrip } from 'tripPlanner/types/common'

interface Props {
  onSelectTrip: (tripId: string) => void
}

function TripSelect({ onSelectTrip }: Props) {
  const { data: trips = EmptyArray as Array<BasicTrip>, isLoading, isError, isFetched } = useTrips()
  const sortedTrips = useMemo(() => {
    return sortBy(trips, (trip) => trip.interactedAt, 'desc')
  }, [trips])

  const modal = useModalElementContext()

  const onClose = useCallback(() => {
    modal.resolve()
  }, [modal])

  const selectAndClose = useCallback(
    (tripId: string) => {
      onSelectTrip(tripId)
      onClose()
    },
    [onClose, onSelectTrip],
  )

  return (
    <Modal
      title="Select trip"
      subtitle="Choose which trip you would like to preview."
      onClose={onClose}
    >
      {isError && <div>Failed to fetch trips</div>}
      {isLoading && <LoadingIndicator inline />}
      {isFetched && sortedTrips.map((trip) => (
        <TripPanel key={trip.id} onSelectTrip={selectAndClose} trip={trip} />
      ))}
    </Modal>
  )
}

export default TripSelect
